body {
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.app-container {
  position: relative;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 0;
  margin: 0px;
  padding: 0px;
}

.image-wrapper {
  position: relative;
  margin: 0px;
  padding: 0px;
  overflow: visible; 
}

.image-grid img {
  display: block; 
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;
  border-radius: 0;
  cursor: pointer; 
  margin: 0px;
  padding: 0px;
}

.image-grid img:hover {
  transform: scale(1.01); 
  margin: 0px;
  padding: 0px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.85);
  z-index: 10;
  position: relative;
}
.image-grid img.closed:hover {
  transform: none; 
  border-radius: 0;
  box-shadow: none; 
  cursor: default;
}

.revert-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  z-index: 11; 
  border: none;
  border-radius: 8px;
  padding: 5px 8px; 
}

.revert-button:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-content {
  background-color: white;
  padding: 0px;
  border-radius: 8px;
  text-align: center;
  max-width: 95%;
  max-height: 95%;
}

.modal-image {
  width: 100%;
  height: auto;
  margin: 0px;
  border-radius: 8px;
}

.modal-buttons button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
}

.modal-buttons .ok {
  margin: 10px;
  padding: 10px 20px;
  border: 0px;
  border-radius: 4px;
  background-color: #00b01a;
  color: white;
  cursor: pointer;
}

.modal-buttons .ok:hover {
  background-color: #008714;
}

.modal-buttons .cancel {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: rgb(255, 0, 0);
  color: white;
  cursor: pointer;
}

.modal-buttons .cancel:hover {
  background-color: rgb(201, 0, 0);
}
